import React from "react";
import Page from "../../components/Page";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Hundflytvast({ data }) {
    const image = data.image.childImageSharp.gatsbyImageData;

    const title = "Albert recension (2022) - Kompletta skolhjälpmedlet för barn"

    const schema = {
      "@context": "https://schema.org/",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Hur mycket kostar Albert?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Albert har tre olika prenumerationer. Deras billigaste alternativ, Albert Basic, kostar 119 kronor per månad."
          }
        },
        {
          "@type": "Question",
          "name": "Hur många barn i familjen kan använda Albert?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Det beror vilken prenumeration som du väljer. För Basic kan man ha ett barn per konto, medan för Plus kan man ha upp till 4 barn på ett konto."
          }
        }
      ]
    }

    return (
        <>
            <Page title={title} description="Om ditt barn har svårt för skolan kan vara bra att se över vilka skolhjälpmedel som ni kan använda för att hjälpa till. Ett sådant skolhjälpmedel är den digitala läroplattformen Albert." schema={schema}>
            <div className="w-full py-3 bg-gray-100"><p className="flex items-center max-w-screen-xl mx-auto px-4 justify-between">Inlägget innehåller reklam genom annonslänkar.</p></div>
                <div className="container px-5 py-6 mx-auto">
                    <article className="blogPost">
                        {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="hejablert" />}
                        <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
                        <p>Om ditt barn har svårt för skolan kan vara bra att se över vilka skolhjälpmedel som ni kan använda för att hjälpa till. Ett sådant skolhjälpmedel är den digitala läroplattformen Albert.</p>
                        <p>Albert är en onlineplattform med flera appar som göra inlärningen roligare och mer meningsfull för barn och ungdomar. På deras plattform kan du öva upp dina kunskaper i <strong>matematik, programmering, läsning och geografi.</strong></p>
                        <p>Vi provade nyligen Albert, och tror att det kan vara ett bra hjälpmedel för många unga som har svårt för skolan. Nedan kan du läsa vår fullständiga recension av läroplattformen.</p>
                        <h2 id="-r-hejalbert-ett-bra-skolhj-lpmedel-">Är HejAlbert ett bra skolhjälpmedel?</h2>
                        <p>Överlag anser vi att Albert är ett bra skolhjälpmedel som kan förbättra <strong>barnens kunskaper</strong> inom matematik, programmering, läsning och geografi.</p>
                        <p>Vi anser däremot att plattformen är som mest komplett när det gäller matematiken och vi hade gärna velat se fler övningar för exempelvis programmering och Engelska.</p>
                        <p>Om ditt barn däremot endast ska använda Albert för matematik är det helt klart en app som är värd att testa.</p>
                        <div className="w-full flex justify-center">
                          <a href="https://track.adtraction.com/t/t?a=1646323917&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                            <button className="mx-auto inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Albert gratis här!</button>
                          </a>
                        </div>
                        <h3 id="-f-r-vem-passar-hejalbert-">För vem passar HejAlbert?</h3>
                        <p>Albert har övningar för barn i åldern <strong>3-16 år</strong> vilket är ett mycket brett spann, och det gör att man kan få ut mycket av läroplattformen Albert.</p>
                        <p>Vi anser däremot att Albert lämpar sig allra bäst för barn i åldern 3-9 år eftersom de har fler övningar för barn i denna åldern.</p>
                        <p>Däremot är Albert fortfarande en bra tjänst för barn i åldern 10-16 om du ute efter en matteapp för att förbättra ditt barns matematiska förmåga.</p>
                        <p>Utöver matematiken så tycker vi nämligen inte att Albert har lika mycket att erbjuda för barn i åldern 10-16 år men samtidigt kan det komma fler tjänster för barnen i denna åldern senare.</p>
                        <h2 id="hur-s-tter-man-ig-ng-med-albert-">Hur sätter man igång med Albert?</h2>
                        <p>För att börja använda Albert behöver man först skapa ett konto på deras hemsida genom att fylla i <strong>din mail och välja ett lösenord</strong>.</p>
                        <GatsbyImage image={data.create.childImageSharp.gatsbyImageData} className="rounded-xl" alt="medlemskap" />

                        <p>När du skapat ditt konto behöver du också fylla i dina kortuppgifter för att få tillgång till applikationen. Du kommer däremot betala något direkt, utan du får självklart testa Albert gratis först för att se om det kan vara något för dig.</p>
                        <p>När ditt konto har skapats kommer du senare få skapa en profil till ditt barn genom att fylla i ditt barns namn och ålder. Barnets ålder kommer senare att användas för att välja ut lämpliga övningar för ditt barn.</p>
                        <GatsbyImage image={data.barnprofil.childImageSharp.gatsbyImageData} className="rounded-xl" alt="medlemskap" />

                        <p>När du skapat en profil kan du enkelt logga in på ditt konto i appen på Android eller iPhone och välja barnets profil. Därefter kan barnet sätta igång och börja öva sina färdigheter på Albert-plattformen.</p>
                        <p>Tänk på att Albert har flera olika appar som du kommer behöva ladda ner. Exempelvis finns <strong>Albert Code, Albert - den digitala läraren, Albert Junior och Albert Geo</strong> och ni kan ladda ner alla som ni känner att ni vill.</p>
                        <p>Albert Junior är den allra mest populära, vilket riktar sig åt de lite mindre barnen som är mellan 3-9 år.</p>
                        <p>Nedan går vi igenom mer vad skolplattformen Albert erbjuder.</p>
                        <h2 id="vad-kan-unga-l-ra-sig-p-plattformen-">Vad kan unga lära sig på plattformen?</h2>
                        <p>HejAlbert har flera olika appar såsom Albert Code, Albert Geo och Albert - den digitala läraren som hjälper barn och ungdomar att lära sig  olika saker.</p>
                        <p>Dessa olika appar lämpar sig även till barn i olika åldrar över spannet 3-16 år.</p>
                        <h3 id="f-r-de-yngre-barnen-3-9-r-finns-">För de yngre barnen (3-9 år) finns:</h3>
                        <p>För de yngre barnen finns det 5 olika appar som passar och dessa är:</p>
                        <ul>
                            <li><strong>Albert Junior</strong> – Här kan barnet lära sig grundläggande matematik såsom addition, subtraktion och multiplikation.</li>
                            <li><strong>Albert Code</strong> – Låt ditt barn börja lära sig grundläggande logik och hur en dator och programmering fungerar.</li>
                            <li><strong>Albert Svenska</strong> – Här kan ditt barn få börja utforska alfabetet och på ett roligt och enkelt sätt, samt lära sig att läsa och skriva.</li>
                            <li><strong>Albert Geo</strong> – Här får barnet träna på geografi genom att peka ut länder, floder, bergsområden samt monument och underverk på världskartan.</li>
                            <li><strong>Albert Engelska</strong> - Här kan ditt barn få börja sig enkel engelska och börja bygga ett grundläggande ordförråd.</li>
                        </ul>
                        <p>Alla dessa finns tillgängliga för både Android och iPhone.</p>
                        <h3 id="f-r-ldre-barn-10-16-r-finns-det-">För äldre barn (10-16 år) finns det:</h3>
                        <p>Det finns två olika appar som riktar sig åt de lite större barnen.</p>
                        <ul>
                            <li><strong>Albert</strong> – Albert är framförallt en matematikapp där barnet kan träna sig på matematiken från grundskolan till högstadiet.</li>
                            <li><strong>Albert Geo</strong> – Här får barnet träna på geografi genom att peka ut länder, floder, bergsområden samt monument och underverk på världskartan.</li>
                        </ul>
                        <p>Som du säkert ser finns det mer tjänster och appar som erbjuds för de lite mindre barnen i åldern 3-9 år, men de barnen som är äldre kan också få en hel del med framförallt matematiken.</p>
                        <h3 id="vilka-tj-nster-erbjuds-f-r-dig-som-f-r-lder-">Vilka tjänster erbjuds för dig som förälder?</h3>
                        <p>Det finns också tjänster och material som erbjuds för dig som förälder och detta består framförallt av statistik på hur det går för ditt barn, samt tips på hur du kan hjälpa ditt barn i din utveckling.</p>
                        <p>Detta tycker vi är väldigt bra eftersom det gör att du som förälder kan bli mer delaktig i ditt barnets lärande, och hjälp till där du märker att ditt barn har det svårt.</p>
                        <p>Om du exempelvis märker att ditt barn verkar ha svårt för en viss del i matematiken blir det helt plötsligt väldigt enkelt att träna lite mer på det tillsammans tills barnet förstår bättre.</p>
                        <div className="w-full flex justify-center">
                          <a href="https://track.adtraction.com/t/t?a=1646323917&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                            <button className="mx-auto inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Albert gratis här!</button>
                          </a>
                        </div>
                        <h2 id="vad-kostar-albert-per-m-nad-">Vad kostar Albert per månad?</h2>
                        <p>Det finns tre olika medlemskap att välja mellan på Albert. Dessa olika medlemskapen heter Basic, Plus och Standard och det kostar och ger åtkomst till olika mycket.</p>
                        <p>Nedan ser du en bild som är tagen från Alberts hemsida över de olika medlemskapen.</p>
                        <GatsbyImage image={data.prices.childImageSharp.gatsbyImageData} className="rounded-xl" alt="medlemskap" />

                        <ul>
                            <li><strong>Albert BAS</strong> (119 kr/mån) – Är ett medlemskap för 1 användare (barn) och för den som vill lära sig matematik.</li>
                            <li><strong>Albert</strong> (159 kr/mån) Är ett medlemskap för 1 användare med alla appar och tjänster (Albert Junior, Albert, Albert Code, ABC och Geo).</li>
                            <li><strong>Albert PLUS</strong> (189 kr/mån) Är ett medlemskap som lämpar sig för större familjer med upp till 4 barn.</li>
                        </ul>
                        <p>Beroende på vad ditt barn behöver hjälp med, eller behöva träna på kommer de olika medlemskapen att lämpa sig olika bra.</p>
                        <p>Om du endast har ett barn och endast vill öva på matte kommer medlemskapet Albert Bas fungera utmärkt, men för barnen som också vill lära sig mer kan ett mer komplett medlemskap vara att föredra.</p>
                        <p>Om du har en större familj med flera barn kommer förmodligen även Albert Plus vara det bästa alternativet eftersom du kan ha flera barn kopplat till samma konto.</p>
                        <div className="w-full flex justify-center">
                          <a href="https://track.adtraction.com/t/t?a=1646323917&as=1759617351&t=2&tk=1" rel="nofollow" target="_blank">
                            <button className="mx-auto inline-flex items-center justify-center px-4 py-3 shadow-md text-base font-bold text-center text-white rounded-full bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-300 ">Testa Albert gratis här!</button>
                          </a>
                        </div>                        <h3 id="kan-man-testa-albert-gratis-innan-man-prenumererar-">Kan man testa Albert gratis innan man prenumererar?</h3>
                        <p>Ja, självklart får du testa Albert gratis innan du väljer om du prenumerera på ett medlemskap eller inte.</p>
                        <p>Du får prova på ett medlemskap hos Albert i över en månad vilket ger dig tillräckligt med tid att bestämma dig om läroplattformen kan vara något för dig.</p>
                        <p>Först när provperioden har gått ut kommer du börja betala för tjänsten, och du kan säga upp ditt medlemskap hos Albert precis när du vill.</p>
                        <h2 id="andra-vanliga-fr-gor-om-albert">Andra vanliga frågor om Albert</h2>
                        <p>Nedan går vi igenom några andra vanliga frågor om Albert.</p>
                        <h3 id="hur-skiljer-sig-albert-fr-n-zcooly-">Hur skiljer sig Albert från Zcooly?</h3>
                        <p>Zcooly är ett annat skolhjälpmedel för att lära sig matematik och därför kan de två tjänsterna ibland jämföras med varandra.</p>
                        <p>Skillnaden mellan Zcooly och HejAlbert är däremot att Zcooly är mer ett spel, medan Albert är mer en tjänst med övningar som liknar det traditionella lärandet.</p>
                        <h3 id="hur-skiljer-sig-albert-fr-n-jaramba-">Hur skiljer sig Albert från Jaramba?</h3>
                        <p><a href="/recensioner/jaramba/">Jaramba</a> är en annan skolapp, men den skiljer sig från Albert genom att den inriktar sig mot mindre barn. Jaramba lämpar sig bättre för barn i åldern 2-5 år.</p>
                        <p>Jaramba har också ett litet annat koncept och de har exempelvis sånger, filmer och spel vilket är något som Albert inte har.</p>
                        <h3 id="hur-s-ger-jag-upp-mitt-albert-medlemskap-">Hur säger jag upp mitt Albert-medlemskap?</h3>
                        <p>Du kan säga upp ditt Albert-medlemskap när som helst, och du kommer endast att debiteras för den tid du faktiskt har använt tjänsten.</p>
                        <p>För att säga upp ditt medlemskap loggar du in på ditt konto på Alberts webbplats och går till &quot;Medlemskap och konto&quot; och sedan &quot;Avsluta medlemskap&quot;.</p>
                        <h3 id="-r-albert-bra-f-r-att-l-ra-barn-programmering-">Är Albert bra för att lära barn programmering?</h3>
                        <p>Albert är okej för att lära mindre barn vissa programmering-koncept, men vi anser att det finns andra appar som lämpar sig bättre såsom Scratch. Vi anser däremot att Albert är en av de allra bästa apparna för att lära barn matematik.</p>
                        <br/>
                        <br/>
                        </article>
                </div>
            </Page>
        </>
    );
}

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "albert.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    prices: file(relativePath: { eq: "albert-prices.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      create: file(relativePath: { eq: "albert-skapa-konto.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      barnprofil: file(relativePath: { eq: "albert-barnprofil.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
   
  }
`;
